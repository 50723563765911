import React from 'react'
import './cymatics.css'


const Cymatics = () => {
  return (
    <>
    <div className='cymatics-container'>
    <div className="ratio ratio-1x1">
        <iframe
            src="https://cymatics.live/website/pages/build/mandelbulbwgl/ff6-portfolio.html"
            title="YouTube video"
            allowfullscreen
        ></iframe>
    </div>
    </div>
    </>
  )
}

export default Cymatics