import { 
  MDBContainer, 
  MDBRow, 
  MDBCol,
  MDBCard,
  MDBBreadcrumb, 
  MDBBreadcrumbItem 
} from 'mdb-react-ui-kit';
import Header from '../../components/header/Header';
import { Link } from 'react-router-dom';

import SEV1 from '../../assets/images/sev/hero-0.jpg';
import SEV2 from '../../assets/images/sev/hoodie-cotton.jpg';
import SEV3 from '../../assets/images/sev/cg-2.png';
import SEV4 from '../../assets/images/sev/website.jpg';
import SEV5 from '../../assets/images/sev/hero-fall.jpg';

import SEV6 from '../../assets/images/sev/hero-1.jpg';
import SEV7 from '../../assets/images/sev/hero-2.jpg';
import SEV8 from '../../assets/images/sev/hero-s-5-1.jpg';
import SEV9 from '../../assets/images/sev/hero-3.jpg';
import SEV10 from '../../assets/images/sev/hero-4.jpg';

import SEV11 from '../../assets/images/sev/hero-5.jpg';
import SEV12 from '../../assets/images/sev/pg-3.jpg';
import SEV13 from '../../assets/images/sev/pg-2.jpg';
import SEV14 from '../../assets/images/sev/shop-mw.jpg';
import SEV15 from '../../assets/images/sev/pg-customer.png';

import SEV16 from '../../assets/images/sev/sev-6.png';



const Sevweb = () => {
  return (
    <>
    
    <MDBContainer>
      <Header/>
      <MDBRow>
      <MDBBreadcrumb className='my-3 mx-3'>
        <MDBBreadcrumbItem>
          <Link to='/'>Home</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
        <Link to='/graphics'>Graphics</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
        SCOTTeVEST Web Graphics
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
      </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={SEV1} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='4' className='mb-5'>
            <MDBCard>
              <img src={SEV2} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
          <MDBCol size='4' className='mb-5'>
            <MDBCard>
              <img src={SEV3} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
          <MDBCol size='4' className='mb-5'>
            <MDBCard>
              <img src={SEV4} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={SEV5} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='6' className='mb-5'>
            <MDBCard>
              <img src={SEV6} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
          <MDBCol size='6' className='mb-5'>
            <MDBCard>
              <img src={SEV7} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={SEV8} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='6' className='mb-5'>
            <MDBCard>
              <img src={SEV9} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
          <MDBCol size='6' className='mb-5'>
            <MDBCard>
              <img src={SEV10} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={SEV11} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
          
        </MDBRow>
        <MDBRow center>
          <MDBCol size='6' className='mb-5'>
            <MDBCard>
              <img src={SEV12} className='img-fluid rounded ' alt='...' />                  
            </MDBCard>
          </MDBCol>
          <MDBCol size='6' className='mb-5'>
            <MDBCard>
              <img src={SEV13} className='img-fluid rounded ' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='5' className='mb-5'>
            <MDBCard>
              <img src={SEV14} className='img-fluid rounded ' alt='...' />                  
            </MDBCard>
          </MDBCol>
          <MDBCol size='7' className='mb-5'>
            <MDBCard>
              <img src={SEV15} className='img-fluid rounded ' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={SEV16} className='img-fluid rounded ' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        
        
      </MDBContainer>
    </>
  )
}

export default Sevweb