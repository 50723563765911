import React from 'react'
import Heroart from '../../components/heroart/Heroart';
import Sectioncode from '../../components/sectioncode/Sectioncode';
import Sectiongraphics from '../../components/sectiongraphics/Sectiongraphics';
import Sectionwebsites from '../../components/sectionwebsites/Sectionwebsites';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <>
    <MDBContainer>
      <MDBRow>
        <Heroart/>
        
      </MDBRow>
      </MDBContainer>
      <MDBContainer >
      <MDBRow>
        <div className="mb-9"></div>
      </MDBRow>
      <MDBRow center>
        <MDBCol size='8' sm='8'>
          <h1>
            SWAY <b>HARNER</b>
          </h1>
        </MDBCol>
        
        
      </MDBRow>
      <MDBRow center>
        <MDBCol size='8' sm='8'>
          <h5>
            DESIGNER / DEVELOPER
          </h5>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <div className="mb-12"></div>
      </MDBRow>
      <MDBRow center className='mb-10'>
        <MDBCol size='12' sm='12'>
        <Link to='/graphics'>
        <MDBBtn className='me-2' color='light'>Graphics</MDBBtn>
        </Link>
        <Link to='/websites'>
        <MDBBtn className='me-2' color='light'>Websites</MDBBtn>
        </Link>
        <Link to='/about'>
        <MDBBtn className='me-2' color='light'>About</MDBBtn>
        </Link>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <Sectioncode/>
    <Sectiongraphics />
    <Sectionwebsites />
    </>
  )
}

export default Home