import React from 'react'
import { 
    MDBContainer, 
    MDBRow, 
    MDBCol,
    MDBCard
 } from 'mdb-react-ui-kit';
import Night from '../../builds/night/Night';
// import KindWords from '../../builds/kindWords/KindWords';
import Cymatics from '../../builds/cymatics/Cymatics';
// import Oscillation from '../../builds/oscillation/Oscillation';

const Sectioncode = () => {
  return (
    <>
    <MDBContainer className='mb-10'>
        <MDBRow center>
            <h6 className='mb-5 text-secondary'>CODED GRAPHICS</h6>
        </MDBRow>
        <MDBRow center>
            <MDBCol lg='6' md='6' sm='8' className='mb-5'>
                <MDBContainer>
                    <Night/>
                </MDBContainer>
            </MDBCol>
            <MDBCol lg='6' md='12' sm='12' className='mb-5'>
                <MDBContainer>
                    <Cymatics/>
                </MDBContainer>
            </MDBCol>
            
      </MDBRow>
    </MDBContainer>
    </>
  )
}

export default Sectioncode