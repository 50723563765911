import { 
  MDBContainer, 
  MDBRow, 
  MDBCol,
  MDBCard,
  MDBBreadcrumb, 
  MDBBreadcrumbItem 
} from 'mdb-react-ui-kit';
import Header from '../../components/header/Header';
import { Link } from 'react-router-dom';

import SEVM1 from '../../assets/images/sev/winter.gif';
import SEVM2 from '../../assets/images/sev/cin-3.gif';
import SEVM3 from '../../assets/images/sev/cin-1.mp4';
import SEVM4 from '../../assets/images/sev/cin-2.gif';
import SEVM5 from '../../assets/images/sev/gif-5.gif';

import SEVM6 from '../../assets/images/sev/chloe-enf.gif';
import SEVM7 from '../../assets/images/sev/hero-6.gif';


const Sevmotion = () => {
  return (
    <>
    
    <MDBContainer>
      <Header/>
      <MDBRow>
      <MDBBreadcrumb className='my-3 mx-3'>
        <MDBBreadcrumbItem>
          <Link to='/'>Home</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
        <Link to='/graphics'>Graphics</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
         SCOTTeVEST Motion Graphics
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
      </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={SEVM1} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='4' className='mb-5'>
            <MDBCard>
              <img src={SEVM2} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
          <MDBCol size='4' className='mb-5'>
            <MDBCard>
            <div className="ratio ratio-1x1">
            <iframe
        src={SEVM3}
        title="Cinimagraph"
        allowfullscreen
      ></iframe> 
     
      </div>
                             
            </MDBCard>
          </MDBCol>
          <MDBCol size='4' className='mb-5'>
            <MDBCard>
              <img src={SEVM4} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='6' className='mb-5'>
            <MDBCard>
              <img src={SEVM5} className='img-fluid rounded ' alt='...' />                  
            </MDBCard>
          </MDBCol>
          <MDBCol size='6' className='mb-5'>
            <MDBCard>
              <img src={SEVM6} className='img-fluid rounded ' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={SEVM7} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  )
}

export default Sevmotion