import React from 'react'
import { 
  MDBContainer, 
  MDBRow, 
  MDBCol,
  MDBCard,
  MDBRipple,
  MDBBreadcrumb, 
  MDBBreadcrumbItem 
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

import IMGWEBHEADER from '../../assets/images/code-header.png'
import IMGWEB1 from '../../assets/port__sev.jpg'
import IMGWEB2 from '../../assets/port__wscc.jpg'
import IMGWEB3 from '../../assets/port__cymatics.jpg'

import IMGWEB4 from '../../assets/port__puppy.jpg'
import IMGWEB5 from '../../assets/port__salad.jpg'
import IMGWEB6 from '../../assets/port__509.jpg'

import IMGWEB7 from '../../assets/port__k9.jpg'
import IMGWEB8 from '../../assets/port__scanned.jpg'
// import IMGWEB9 from '../../assets/port__puresalon.jpg'
import IMGGRAPH9 from '../../assets/design/placeholder-logo.png'

const Websites = () => {
  return (
    <>
      <MDBContainer>
      <MDBRow>
      <MDBCard>
        <img src={IMGWEBHEADER} width="100%" alt='website landing header'/>

      </MDBCard>
    </MDBRow>
    </MDBContainer>
    <MDBContainer>
      <MDBRow>
      <MDBBreadcrumb className='my-3 mx-3'>
        <MDBBreadcrumbItem>
          <Link to='/'>Home</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>Web</MDBBreadcrumbItem>
      </MDBBreadcrumb>
      </MDBRow>
        <MDBRow center>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGWEB1} className='img-fluid rounded shadow-4' alt='...' />                  
              <a href="https://www.scottevest.com/" target="_blank" rel='noreferrer'>
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>SCOTTeVEST</p> 
                      </div>
                    </div>
              </a>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGWEB2} className='img-fluid rounded shadow-4' alt='...' />                  
              <a href="https://seattleconventioncenter.com/" target="_blank" rel='noreferrer'>
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>Seattle Convention Center
                        <br/>
                        <small className='text-muted'>Formally WSCC</small>
                        </p> 
                      </div>
                    </div>
              </a>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGWEB3} className='img-fluid rounded shadow-4' alt='...' />                  
              <a href="https://cymatics.live/" target="_blank" rel='noreferrer'>
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                      <p className='text-dark mb-0'>Cymatics Live</p> 

                      </div>
                    </div>
              </a>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow center>
        <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGWEB8} className='img-fluid rounded shadow-4' alt='...' />                  
              <a href="https://scanned.media/" target="_blank" rel='noreferrer'>
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                      <p className='text-dark mb-0'>Scanned Media</p> 

                      </div>
                    </div>
              </a>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGWEB5} className='img-fluid rounded shadow-4' alt='...' />                  
              <a href="https://spokanesalads.com/" target="_blank" rel='noreferrer'>
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                      <p className='text-dark mb-0'>Spokane Salads</p> 

                      </div>
                    </div>
              </a>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGWEB6} className='img-fluid rounded shadow-4' alt='...' />                  
              <a href="https://flameinnovation.com/" target="_blank" rel='noreferrer'>
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>Flame Innovation
                        <br/>
                        <small className='text-muted'>Formally 509 Stoves</small>
                        </p> 
                      </div>
                    </div>
              </a>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow center>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGWEB7} className='img-fluid rounded shadow-4' alt='...' />                  
              <a href="https://www.methodk9.com/" target="_blank" rel='noreferrer'>
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                      <p className='text-dark mb-0'>Method K9</p> 

                      </div>
                    </div>
              </a>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGWEB4} className='img-fluid rounded shadow-4' alt='...' />                  
              <a href="https://puppypadspokane.com/" target="_blank" rel='noreferrer'>
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                      <p className='text-dark mb-0'>PuppyPads</p> 

                      </div>
                    </div>
              </a>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' md='4' className='mb-5'>
          <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGGRAPH9} className='img-fluid rounded ' alt='...' />                  
              
              </MDBRipple>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
 
    </>
  )
}

export default Websites