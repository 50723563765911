import { 
    MDBContainer, 
    MDBRow, 
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBreadcrumb, 
    MDBBreadcrumbItem,
    MDBBtn,
    MDBIcon
  } from 'mdb-react-ui-kit';
  import { Link } from 'react-router-dom';

import HEADERIMG from '../../assets/images/ss/ss-3.jpg'
import PROFILEIMG from '../../assets/me-3.png'


const About = () => {


  return (
    <>
        <MDBContainer>
            <MDBRow>
                <img src={HEADERIMG} width="100%" />
            </MDBRow>
        </MDBContainer>
        <MDBContainer>
      <MDBRow>
      <MDBBreadcrumb className='my-3 mx-3'>
        <MDBBreadcrumbItem>
          <Link to='/'>Home</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>About</MDBBreadcrumbItem>
      </MDBBreadcrumb>
      </MDBRow>
      <MDBRow>
        <MDBCol lg='3' md='6' >
        <MDBCard>
        <img src={PROFILEIMG} width="100%" />
        </MDBCard>

        </MDBCol>
        <MDBCol lg='9' md='6'>
            <MDBCard>
                <MDBCardBody className='text-start'>
                    <MDBCardTitle className='text-muted'>About Me</MDBCardTitle>
                    <MDBCardText className='text-secondary'>
                        <p>
                            I'm a multidisciplinary professional with 7+ years of experience in graphic design and web design / development, currently based out of Seattle, Wa. I've done work for a wide variety of industries and for companies small and large. I focus on creating clean and simple visuals, user-centered content, and intuitive user experiences. I enjoy working through the full scope of a project, from research and strategy, IA and design systems, all the way to pixel-perfect designs and in some cases fullstack developement.
                        </p>
                        <p>
                        Outside of work I'm usually bouldering, hiking, producing music, painting or working on projects with friends. I am always out looking for inspiration on projects I’m working on or finding people to collaborate with. Everyone has a unique story and perspective to life, and I enjoy listening to all of it while finding a way to streamline their messaging / optimize methods for exposure (if that is something desired).
                        </p>
                        <p>
                        If you're here feeling me out, please check out my work or reach out if there are specific design / development samples you are looking for. I'd love to chat about projects I could help you out with!
                        </p>                    
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard>
            <MDBRow className='my-5'>
                <MDBCol md='12' lg='6' className='mb-5'>
                <MDBCard className='py-4'>
                <MDBRow>
                        <MDBCol sm='12' md='12' lg='4'>
                        <span className=' text-muted'><small>EMAIL:</small> </span>    
                        </MDBCol>
                        <MDBCol sm='12' md='12' lg='8'>
                        <a className='text-secondary' href="mailto:sway.harner@gmail.com"><small>sway.harner@gmail.com</small> </a>        
                        </MDBCol>
                    </MDBRow>
                
            </MDBCard>
                </MDBCol>
                <MDBCol md='12' lg='6' className='mb-5'>
                <MDBCard className='py-4'>
                <MDBRow>
                        <MDBCol md='12' lg='4' className='d-flex align-items-center justify-content-center'>
                        <span className='px-3 text-muted'><small>SOCIAL:</small> </span>    
                        </MDBCol>
                        <MDBCol md='12' lg='8'>
                        <MDBBtn floating size='sm' tag='a' color='' className='m-1' 
                                style={{ backgroundColor: '#0082ca' }} 
                                href='https://www.linkedin.com/in/sway-harner-b182b279/' >
                                <MDBIcon fab icon="linkedin-in" />                            
                            </MDBBtn>
                            <MDBBtn floating size='sm' tag='a' color='' className='m-1' 
                                style={{ backgroundColor: '#333333' }} 
                                href='https://github.com/GeppettosParadox'>
                                <MDBIcon fab icon="github" />  
                            </MDBBtn>
                            <MDBBtn floating size='sm' tag='a' color='' className='m-1' 
                                style={{ backgroundColor: '#3b5998' }} 
                                href='https://www.facebook.com/sway.harner'>
                                <MDBIcon fab icon='facebook-f' />                          
                            </MDBBtn>
                            <MDBBtn floating size='sm' tag='a' color='' className='m-1' 
                                style={{ backgroundColor: '#c61118' }} 
                                href='https://www.pinterest.com/mistersway/'>
                                <MDBIcon fab icon="pinterest" />    
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
            </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBCol>
      </MDBRow>
      </MDBContainer>
    </>
  )
}

export default About