import React from 'react';
import './heroart.scss';

const HeroArt = () => {
  return (
    <>
    <div class="box">
  <div class="sphere"></div>

  <div class="cy">
    <div class="cy__cy top"></div>
    <div class="cy__lec mid"></div>
    <div class="cy__cy btm"></div>
    <div class="sphare-shadow"></div>
  </div>
</div>
    </>
  )
}

export default HeroArt