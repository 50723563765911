import React from 'react'
import { 
    MDBContainer, 
    MDBRow, 
    MDBCol,
    MDBCard
  } from 'mdb-react-ui-kit';

import './header.css'
import HEADERLOGO from '../../assets/favicon.ico'


const Header = () => {
  return (
    <>
        <MDBContainer>
            <MDBRow>
                <MDBCard>
                    <MDBRow className=''>
                        <MDBCol className='py-3'>
                            <hr/>
                        </MDBCol>
                        <MDBCol sm='1' md='2' lg='1' className='d-flex align-items-center justify-content-center'>
                            <img id='navLogo' src={HEADERLOGO} className='img-fluid rounded'  alt='...' />                  
                        </MDBCol>
                        <MDBCol className='py-3'>
                            <hr/>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBRow>
        </MDBContainer>
    </>
  )
}

export default Header