import './night.css';

const Night = () => {
  return (
    <>
          <div class="night-container">
    <div class="cloud-one ">
      <div class="tail2"></div>
      <div class="bump1-1"></div>
      <div class="bump2-2"></div>
    </div>
    <div class="cloud-two ">
      <div class="tail"></div>
      <div class="bump1"></div>
      <div class="bump2"></div>
    </div>
    <div class="cloud-three ">
      <div class="tail2"></div>
      <div class="bump1-1"></div>
      <div class="bump2-2"></div>
    </div>
    <div class="cloud-four ">
      <div class="tail2"></div>
      <div class="bump1-1"></div>
      <div class="bump2-2"></div>
    </div>
    <div class="cloud-five ">
      <div class="tail"></div>
      <div class="bump1"></div>
      <div class="bump2"></div>
    </div>
    <div class="mountain">
      <div class="mountain-top">
        <div class="mountain-cap-1"></div>
        <div class="mountain-cap-2"></div>
        <div class="mountain-cap-3"></div>
      </div>
    </div>
    <div class="mountain-two">
      <div class="mountain-top">
        <div class="mountain-cap-1"></div>
        <div class="mountain-cap-2"></div>
        <div class="mountain-cap-3"></div>
      </div>
    </div>
     <div class="mountain-three">
      <div class="mountain-top">
        <div class="mountain-cap-1"></div>
        <div class="mountain-cap-2"></div>
        <div class="mountain-cap-3"></div>
      </div>
    </div>
  <div class="landscape-2">
      <div class="ground-2"></div>
    </div>
  <div class="landscape-3">
    <div class="ground-1"></div>

  </div>
  <div class="landscape-4">
  </div>
  <div class="pond">
    <div class="pond-base"></div>
    <div class="pond-ice"></div>
    <div class="ice-line-1"></div>
    <div class="ice-line-2"></div>
    <div class="pond-stars"></div>

  </div>
  <div class="star1"></div>
  <div class="star2"></div>
  <div class="star3"></div>
  <div class="star4"></div>
  <div class="star5"></div>
  <div class="star6"></div>
  <div class="star7"></div>
  <div class="star8"></div>
  <div class="star9"></div>
  <div class="star10"></div>
  <div class="star11"></div>
  <div class="moon"></div>
  <div class="moon2"></div>

  </div>
    </>
  )
}

export default Night