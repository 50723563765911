import { 
  MDBContainer, 
  MDBRow, 
  MDBCol,
  MDBCard,
  MDBBreadcrumb, 
  MDBBreadcrumbItem 
} from 'mdb-react-ui-kit';
import Header from '../../components/header/Header';
import { Link } from 'react-router-dom';

import SB1 from '../../assets/images/sleepless/sleepless-logo.jpg';
import SB3 from '../../assets/images/sleepless/sleepless-ticket.jpg';
import SB4 from '../../assets/images/sleepless/sleepless-lanyard.jpg';
import SB5 from '../../assets/images/sleepless/sleepless-t-shirt.jpg';
import SB6 from '../../assets/images/sleepless/sleepless-music-player.jpg';
import SB7 from '../../assets/images/sleepless/sleepless-live.jpg';




const Sleeplessbrand = () => {
  return (
    <>
    <MDBContainer>
      <Header/>
      <MDBRow>
      <MDBBreadcrumb className='my-3 mx-3'>
        <MDBBreadcrumbItem>
          <Link to='/'>Home</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
        <Link to='/graphics'>Graphics</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
         Sleepless Brand
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
      </MDBRow>
        <MDBRow center>
          <MDBCol size='12'  className='mb-5'>
            <MDBCard>
              <img src={SB1} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
       
        <MDBRow center>
          <MDBCol size='12'  className='mb-5'>
            <MDBCard>
              <img src={SB3} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12'  className='mb-5'>
            <MDBCard>
              <img src={SB4} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='6'  className='mb-5'>
            <MDBCard>
              <img src={SB5} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
          <MDBCol size='6'  className='mb-5'>
            <MDBCard>
              <img src={SB6} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12'  className='mb-5'>
            <MDBCard>
              <img src={SB7} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  )
}

export default Sleeplessbrand