import React from 'react'
import { 
    MDBContainer, 
    MDBRow, 
    MDBCol,
    MDBCard,
    MDBRipple,
    MDBBtn
 } from 'mdb-react-ui-kit';
 import { Link } from 'react-router-dom';

 import SEVMOTION from '../../assets/design/sev-motion-graphics.gif'
 import GRAPHICS1 from '../../assets/design/iron-tribe.png'
 import GRAPHICS2 from '../../assets/design/sleepless-branding.png'
 import GRAPHICS3 from '../../assets/design/wireless-advocate-landing.png'


const Sectiongraphics = () => {
  return (
    <>
    <MDBContainer className='mb-10'>
        <MDBRow center>
            <h6 className='mb-5 text-secondary'>BUILT GRAPHICS</h6>
        </MDBRow>
        <MDBRow center>
            <MDBCol lg='6' md='6' sm='8' className='mb-5'>
                <MDBContainer>
                <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={SEVMOTION} className='img-fluid rounded shadow-4' alt='...' />                  
              <Link to="/graphics/scottevest-motion-graphics">
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>SCOTTeVEST Motion Graphics
                        <br/>
                        <small className='text-muted'>Graphic Design</small>
                        </p> 
                      </div>
                    </div>
              </Link>
              </MDBRipple>
            </MDBCard>
                </MDBContainer>
            </MDBCol>
            <MDBCol lg='6' md='6' sm='8' className='mb-5'>
                <MDBContainer>
                <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={GRAPHICS1} className='img-fluid rounded shadow-4' alt='...' />                  
              <Link to="/graphics/iron-tribe">
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>Iron Tribe
                        <br/>
                        <small className='text-muted'>Editorial Design</small>
                        </p> 
                      </div>
                    </div>
              </Link>
              </MDBRipple>
            </MDBCard>
                </MDBContainer>
            </MDBCol>
      </MDBRow>
        <MDBRow center>
            <MDBCol lg='6' md='6' sm='8' className='mb-5'>
                <MDBContainer>
                <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={GRAPHICS2} className='img-fluid rounded shadow-4' alt='...' />                  
              <Link to="/graphics/sleepless-brand">
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>Sleepless
                        <br/>
                        <small className='text-muted'>Brand Identity / Logo Design</small>
                        </p> 
                      </div>
                    </div>
              </Link>
              </MDBRipple>
            </MDBCard>
                </MDBContainer>
            </MDBCol>
            <MDBCol lg='6' md='6' sm='8' className='mb-5'>
                <MDBContainer>
                <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={GRAPHICS3} className='img-fluid rounded shadow-4' alt='...' />                  
              <Link to="/graphics/wireless-advocates-landing-pages">
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>Wireless Advocates Landing Pages
                        <br/>
                        <small className='text-muted'>Graphic Design</small>
                        </p> 
                      </div>
                    </div>
              </Link>
              </MDBRipple>
            </MDBCard>
                </MDBContainer>
            </MDBCol>
      </MDBRow>
      <MDBRow center className='mb-10'>
        <MDBCol size='12' sm='12'>
        
        <Link to='/graphics'>
        <MDBBtn size='sm' className='me-2' color='light' style={{textTransform:'lowercase'}}><span className='text-secondary'>view more</span></MDBBtn>
        </Link>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </>
  )
}

export default Sectiongraphics