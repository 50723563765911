import { 
  MDBContainer, 
  MDBRow, 
  MDBCol,
  MDBCard,
  MDBBreadcrumb, 
  MDBBreadcrumbItem 
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

import Header from '../../components/header/Header';
import LOGOS1 from '../../assets/images/logos/big-boppa.png';
import LOGOS2 from '../../assets/images/logos/xenon-kitty.png';
import LOGOS3 from '../../assets/images/logos/rndm-chld.png';
import LOGOS4 from '../../assets/images/logos/dazzle.png';
import LOGOS5 from '../../assets/images/logos/tekhne.png';
import LOGOS6 from '../../assets/images/logos/droneology.png';


const Logos = () => {
  return (
    <>
    
    <MDBContainer>
      <Header/>
      <MDBRow>
      <MDBBreadcrumb className='my-3 mx-3'>
        <MDBBreadcrumbItem>
          <Link to='/'>Home</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
        <Link to='/graphics'>Graphics</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
         Logos
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
      </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={LOGOS1} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={LOGOS2} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={LOGOS3} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={LOGOS4} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={LOGOS5} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={LOGOS6} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  )
}

export default Logos