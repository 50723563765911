import { 
  MDBContainer, 
  MDBRow, 
  MDBCol,
  MDBCard,
  MDBBreadcrumb, 
  MDBBreadcrumbItem 
} from 'mdb-react-ui-kit';
import Header from '../../components/header/Header';
import { Link } from 'react-router-dom';

import PCS1 from '../../assets/images/pcs/drone-2.jpg';
import PCS2 from '../../assets/images/pcs/graphic-1.jpg';
import PCS3 from '../../assets/images/pcs/graphic-2.jpg';
import PCS4 from '../../assets/images/pcs/graphic-4.jpg';
import PCS5 from '../../assets/images/pcs/pirate-1.jpg';

import PCS6 from '../../assets/images/pcs/pirate-3.png';
import PCS7 from '../../assets/images/pcs/b2b-1.jpg';



const Pcs = () => {
  return (
    <>
    
    <MDBContainer>
      <Header/>
      <MDBRow>
      <MDBBreadcrumb className='my-3 mx-3'>
        <MDBBreadcrumbItem>
          <Link to='/'>Home</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
        <Link to='/graphics'>Graphics</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
         PCS ED!ventures
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
      </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={PCS1} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={PCS2} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={PCS3} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={PCS4} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={PCS5} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={PCS6} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={PCS7} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  )
}

export default Pcs