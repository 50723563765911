import './App.css';
import Home from './views/home/Home';
import Graphics from './views/graphics/Graphics';
import Websites from './views/websites/Websites';
import About from './views/about/About';

import Sevmotion from './views/sevmotion/Sevmotion';
import Sevweb from './views/sevweb/Sevweb';
import Irontribe from './views/irontribe/Irontribe';
import Logos from './views/logos/Logos';
import Wireless from './views/wireless/Wireless';
import Pcs from './views/pcs/Pcs';

import Footer from './components/footer/Footer';

import { Routes, Route } from 'react-router-dom';
import Sleeplessbrand from './views/sleeplessbrand/Sleeplessbrand';
import Sleeplesscontent from './views/sleeplesscontent/Sleeplesscontent';

function App() {
  return (
    <div className="App">
        
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/graphics' element={<Graphics/>} />
        <Route path='/graphics/sleepless-brand' element={<Sleeplessbrand/>} />
        <Route path='/graphics/sleepless-content' element={<Sleeplesscontent/>} />
        <Route path='/graphics/scottevest-motion-graphics' element={<Sevmotion/>} />
        <Route path='/graphics/scottevest-web-graphics' element={<Sevweb/>} />
        <Route path='/graphics/iron-tribe' element={<Irontribe/>} />
        <Route path='/graphics/logos' element={<Logos/>} />
        <Route path='/graphics/wireless-advocates-landing-pages' element={<Wireless/>} />
        <Route path='/graphics/pcs-edventures' element={<Pcs/>} />
        <Route path='/websites' element={<Websites/>} />
        <Route path='/about' element={<About/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
