import { 
  MDBContainer, 
  MDBRow, 
  MDBCol,
  MDBCard,
  MDBBreadcrumb, 
  MDBBreadcrumbItem 
} from 'mdb-react-ui-kit';
import Header from '../../components/header/Header';
import { Link } from 'react-router-dom';

import SC1 from '../../assets/images/sleepless/portfolio-sleepless-may.jpg';
import SC2 from '../../assets/images/sleepless/portfolio-sleepless-aug.jpg';
import SC3 from '../../assets/images/sleepless/portfolio-sleepless-jul.jpg';
import SC4 from '../../assets/images/sleepless/portfolio-sleepless-oct.jpg';
import SC5 from '../../assets/images/sleepless/portfolio-sleepless-sept.jpg';

const Sleeplesscontent = () => {
  return (
    <>
    
    <MDBContainer>
      <Header/>
      <MDBRow>
      <MDBBreadcrumb className='my-3 mx-3'>
        <MDBBreadcrumbItem>
          <Link to='/'>Home</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
        <Link to='/graphics'>Graphics</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
         Sleepless Event Graphics
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
      </MDBRow>
      <MDBRow center>
          <MDBCol size='12'  className='mb-5'>
            <MDBCard>
              <img src={SC1} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12'  className='mb-5'>
            <MDBCard>
              <img src={SC2} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12'  className='mb-5'>
            <MDBCard>
              <img src={SC3} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12'  className='mb-5'>
            <MDBCard>
              <img src={SC4} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12'  className='mb-5'>
            <MDBCard>
              <img src={SC5} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  )
}

export default Sleeplesscontent