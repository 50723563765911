import { 
  MDBContainer, 
  MDBRow, 
  MDBCol,
  MDBCard,
  MDBBreadcrumb, 
  MDBBreadcrumbItem 
} from 'mdb-react-ui-kit';
import Header from '../../components/header/Header';
import { Link } from 'react-router-dom';


import IT1 from '../../assets/images/np/np-9.jpg';
import IT2 from '../../assets/images/np/np-1.jpg';
import IT3 from '../../assets/images/np/np-2.jpg';
import IT4 from '../../assets/images/np/np-3.jpg';
import IT5 from '../../assets/images/np/np-4.jpg';
import IT6 from '../../assets/images/np/np-6.jpg';
import IT7 from '../../assets/images/np/np-7.jpg';



const Irontribe = () => {
  return (
    <>
    
    <MDBContainer>
      <Header/>
      <MDBRow>
      <MDBBreadcrumb className='my-3 mx-3'>
        <MDBBreadcrumbItem>
          <Link to='/'>Home</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
        <Link to='/graphics'>Graphics</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
         Iron Tribe
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
      </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={IT1} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={IT2} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={IT3} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={IT4} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={IT5} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={IT6} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol size='12' className='mb-5'>
            <MDBCard>
              <img src={IT7} className='img-fluid rounded shadow-4' alt='...' />                  
            </MDBCard>
          </MDBCol>
        </MDBRow>
        
      </MDBContainer>
    </>
  )
}

export default Irontribe