import React from 'react'
import { 
  MDBContainer, 
  MDBRow, 
  MDBCol,
  MDBCard,
  MDBRipple,
  MDBBreadcrumb, 
  MDBBreadcrumbItem 
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

import IMGGRAPH1 from '../../assets/design/sleepless-branding.png';
import IMGGRAPH2 from '../../assets/design/sleepless-event-graphic.png';
import IMGGRAPH3 from '../../assets/design/iron-tribe.png';

import IMGGRAPH4 from '../../assets/design/sev-web-graphic.png';
import IMGGRAPH5 from '../../assets/design/sev-motion-graphics.gif';
import IMGGRAPH6 from '../../assets/design/wireless-advocate-landing.png';

import IMGGRAPH7 from '../../assets/design/pcs-graphic-design.png';
import IMGGRAPH8 from '../../assets/design/other-logos.png';
import IMGGRAPH9 from '../../assets/design/placeholder-logo.png';

import IMGCARO from '../../assets/images/ssc/ssc-banner-2.gif';


const Graphics = () => {
  return (
    <>
      <MDBContainer>
      <MDBRow>
      <img src={IMGCARO} width="100%" alt='' />
    </MDBRow>
    </MDBContainer>
    <MDBContainer>
      <MDBRow>
      <MDBBreadcrumb className='my-3 mx-3'>
        <MDBBreadcrumbItem>
          <Link to='/'>Home</Link>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>Graphics</MDBBreadcrumbItem>
      </MDBBreadcrumb>
      </MDBRow>
        <MDBRow center>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGGRAPH1} className='img-fluid rounded shadow-4' alt='...' />                  
              <Link to="/graphics/sleepless-brand">
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>Sleepless
                        <br/>
                        <small className='text-muted'>Brand Identity / Logo Design</small>
                        </p> 
                      </div>
                    </div>
              </Link>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGGRAPH2} className='img-fluid rounded shadow-4' alt='...' />                  
              <Link to="/graphics/sleepless-content">
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>Sleepless Event Graphics
                        <br/>
                        <small className='text-muted'>Graphic Design</small>
                        </p> 
                      </div>
                    </div>
              </Link>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGGRAPH3} className='img-fluid rounded shadow-4' alt='...' />                  
              <Link to="/graphics/iron-tribe">
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>Iron Tribe
                        <br/>
                        <small className='text-muted'>Editorial Design</small>
                        </p> 
                      </div>
                    </div>
              </Link>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow center>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGGRAPH4} className='img-fluid rounded shadow-4' alt='...' />                  
              <Link to="/graphics/scottevest-web-graphics">
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>SCOTTeVEST Web Graphics
                        <br/>
                        <small className='text-muted'>Graphic Design</small>
                        </p> 
                      </div>
                    </div>
              </Link>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGGRAPH5} className='img-fluid rounded shadow-4' alt='...' />                  
              <Link to="/graphics/scottevest-motion-graphics">
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>SCOTTeVEST Motion Graphics
                        <br/>
                        <small className='text-muted'>Graphic Design</small>
                        </p> 
                      </div>
                    </div>
              </Link>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGGRAPH6} className='img-fluid rounded shadow-4' alt='...' />                  
              <Link to="/graphics/wireless-advocates-landing-pages">
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>Wireless Advocates Landing Pages
                        <br/>
                        <small className='text-muted'>Graphic Design</small>
                        </p> 
                      </div>
                    </div>
              </Link>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow center>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGGRAPH7} className='img-fluid rounded shadow-4' alt='...' />                  
              <Link to="/graphics/pcs-edventures">
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>PCS ED!ventures
                        <br/>
                        <small className='text-muted'>Graphic Design / Photography / Branding </small>
                        </p> 
                      </div>
                    </div>
              </Link>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGGRAPH8} className='img-fluid rounded shadow-4' alt='...' />                  
              <Link to="/graphics/logos">
                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <p className='text-dark mb-0'>Logos
                        <br/>
                        <small className='text-muted'>Graphic Design</small>
                        </p> 
                      </div>
                    </div>
              </Link>
              </MDBRipple>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' md='4' className='mb-5'>
            <MDBCard>
              <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
              <img src={IMGGRAPH9} className='img-fluid rounded ' alt='...' />                  
              
              </MDBRipple>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
 
    </>
  )
}

export default Graphics