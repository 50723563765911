import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';


const Footer = () => {
  return (
    <>
        <MDBContainer>
            <MDBRow>
            <hr />
            </MDBRow>
            <MDBRow className='mb-5'>
                <MDBCol className='text-start text-secondary mb-2' sm='12' md='6'>
                <small>2023 © Sway Harner</small>
                </MDBCol>
                <MDBCol className='text-end text-secondary mb-2' sm='12' md='6'>
                <small>
                For all design and code inquiries: &nbsp;
                    <a  className="text-secondary" href="mailto:sway.harner@gmail.com">
                    sway.harner@gmail.com
                    </a>
                </small>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    </>
  )
}

export default Footer