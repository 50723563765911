import React from 'react'
import { 
    MDBContainer, 
    MDBRow, 
    MDBCol,
    MDBCard,
    MDBRipple,
    MDBBtn
 } from 'mdb-react-ui-kit';
 import { Link } from 'react-router-dom';

 import WEBSITE1 from '../../assets/port__sev.jpg'
 import WEBSITE2 from '../../assets/port__wscc.jpg'
 import WEBSITE3 from '../../assets/port__puppy.jpg'
 import WEBSITE4 from '../../assets/port__k9.jpg'

const Sectionwebsites = () => {
  return (
    <>
    <MDBContainer className='mb-10'>
    <MDBRow center>
        <h6 className='mb-5 text-secondary'>WEBSITES</h6>
    </MDBRow>
    <MDBRow center>
        <MDBCol lg='6' md='6' sm='8' className='mb-5'>
            <MDBContainer>
            <MDBCard>
          <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
          <img src={WEBSITE1} className='img-fluid rounded shadow-4' alt='...' />                  
          <a href="https://www.scottevest.com/" target="_blank" rel='noreferrer'>
                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <p className='text-dark mb-0'>SCOTTeVEST</p> 
                  </div>
                </div>
          </a>
          </MDBRipple>
        </MDBCard>
            </MDBContainer>
        </MDBCol>
        <MDBCol lg='6' md='6' sm='8' className='mb-5'>
            <MDBContainer>
            <MDBCard>
          <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
          <img src={WEBSITE2} className='img-fluid rounded shadow-4' alt='...' />                  
          <a href="https://seattleconventioncenter.com/" target="_blank" rel='noreferrer'>
                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <p className='text-dark mb-0'>Seattle Convention Center
                    <br/>
                    <small className='text-muted'>Formally WSCC</small>
                    </p> 
                  </div>
                </div>
          </a>
          </MDBRipple>
        </MDBCard>
            </MDBContainer>
        </MDBCol>
  </MDBRow>
    <MDBRow center>
        <MDBCol lg='6' md='6' sm='8' className='mb-5'>
            <MDBContainer>
            <MDBCard>
          <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
          <img src={WEBSITE3} className='img-fluid rounded shadow-4' alt='...' />                  
          <a href="https://puppypadspokane.com/" target="_blank" rel='noreferrer'>
                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                  <p className='text-dark mb-0'>PuppyPads</p> 

                  </div>
                </div>
          </a>
          </MDBRipple>
        </MDBCard>
            </MDBContainer>
        </MDBCol>
        <MDBCol lg='6' md='6' sm='8' className='mb-5'>
            <MDBContainer>
            <MDBCard>
          <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
          <img src={WEBSITE4} className='img-fluid rounded shadow-4' alt='...' />                  
          <a href="https://www.methodk9.com/" target="_blank" rel='noreferrer' >
                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.9)' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                  <p className='text-dark mb-0'>Method K9</p> 

                  </div>
                </div>
          </a>
          
          </MDBRipple>
        </MDBCard>
            </MDBContainer>
        </MDBCol>
  </MDBRow>
  <MDBRow center className='mb-10'>
    <MDBCol size='12' sm='12'>
    <Link to='/websites'>
    <MDBBtn size='sm' className='me-2' color='light' style={{textTransform:'lowercase'}}><span className='text-secondary'>view more</span></MDBBtn>
        </Link>

    </MDBCol>
  </MDBRow>
</MDBContainer>
</>
  )
}

export default Sectionwebsites